(function($) {
	
	const $block = $('.wp-block-metropolis-interactive-body');
	
	$block.each( function() {
		
		const $this = jQuery(this);
		const $bodyImage = $this.find('.body-image');
		const $sections = $this.find('.wp-block-metropolis-interactive-body-content');
		
		$sections.each(function() {
			const $contentBlock = jQuery(this);
			const $tooltip = jQuery("<span>", {
				class: "tooltip",
				css: {
					top:  $contentBlock.data('y') + "%",
					left:  $contentBlock.data('x') + "%",
				},
				"data-block": $contentBlock.data('block'),
			});
			$bodyImage.append($tooltip);
		});
		
		const $tooltips = $this.find('.body-image .tooltip');
		
		$sections.first().addClass('active');
		$tooltips.first().addClass('active');
		
		$tooltips.on('mouseenter click', function() {
			const $tooltip =  $(this);
			const index = $tooltip.index();
			$sections.removeClass('active').eq(index - 1).addClass('active');
			$tooltips.removeClass('active').filter(this).addClass('active');
		});
	
	});
	
})(jQuery);